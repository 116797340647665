import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { formatIccidNumber } from 'helpers/Formatters'
import { FormGroup } from 'reactstrap'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import styled from 'styled-components'

class IccidNumbersModal extends Component {
  constructor(props) {
    super(props)

    const {
      selectedOrderItem: { iccidNumbers },
    } = props

    this.state = {
      openSfcModal: false,
      openEsimModal: false,
      confirmLabel: 'OK',
    }

    const fields = {
      iccidNumbers: {
        label: 'ICCID Numbers',
        value: iccidNumbers ? iccidNumbers.join('\n') : null,
        placeholder: 'Use ENTER key to add multiple ICCID Numbers',
      },
    }

    this.form = new Form({ fields }, { name: 'OrderItemIccidNumbers', handleSubmit: this.handleSubmit })
  }

  componentWillUnmount() {
    this.props.orderItemIccidNumberRepository.clearCrudData()
  }

  formatIccidNumbers = () => {
    const iccidNumbers = this.form
      .$('iccidNumbers')
      .values()
      .trim()
      .split('\n')
      .map(iccidNumber => formatIccidNumber(iccidNumber))
      .join('\n')

    this.form.$('iccidNumbers').set(iccidNumbers)

    if (!iccidNumbers?.length && this.props.selectedOrderItem.iccidNumbers?.length) {
      this.setState({ confirmLabel: 'Remove' })
    } else {
      this.setState({ confirmLabel: 'OK' })
    }
  }

  handleSubmit = proceed => {
    const { iccidNumbers } = this.form.values()
    const { handleSubmit } = this.props

    handleSubmit(iccidNumbers, proceed)
  }

  triggerSubmit = () => {
    this.buttonSubmit.click()
  }

  render() {
    const {
      orderItemIccidNumberRepository: {
        CRUD: { errors, loading, submitting },
      },
      open,
      onClose,
      readOnly,
    } = this.props
    const { confirmLabel } = this.state

    return (
      <React.Fragment>
        <Modal
          title={readOnly ? 'ICCID Numbers' : 'Enter ICCID Numbers'}
          loading={loading}
          open={open}
          closeLabel={readOnly ? 'Close' : 'Cancel'}
          hideConfirm={!allowedToOrderRole() || readOnly}
          confirmLabel={confirmLabel}
          onConfirm={this.triggerSubmit}
          onClose={onClose}
        >
          <FormContainer
            onSubmit={e => {
              e.stopPropagation()
              if (!readOnly) {
                this.formatIccidNumbers()
                this.form.onSubmit(e, { onSuccess: () => this.handleSubmit() })
              }
            }}
            errors={errors}
            plain
          >
            <FormGroupStyled>
              <Input
                field={this.form.$('iccidNumbers')}
                type="textarea"
                rows="10"
                onBlur={() => {
                  !readOnly && this.formatIccidNumbers()
                }}
                readOnly={readOnly || submitting}
              />
            </FormGroupStyled>
            <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
          </FormContainer>
        </Modal>
      </React.Fragment>
    )
  }
}

const FormGroupStyled = styled(FormGroup)`
  &.form-group {
    textarea.form-control {
      min-height: 150px;
      height: ${({ nolabel, haserror }) => (nolabel ? (haserror ? 'calc(100% - 15px)' : '100%') : 'auto')};
    }
  }
`

export default decorate(inject('orderItemIccidNumberRepository')(observer(IccidNumbersModal)), {})
