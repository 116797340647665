import React, { Component } from 'react'

import { Col, Container, NavbarBrand, Row } from 'reactstrap'
import { ENV_PRODUCTION } from 'definitions'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import logo from 'images/logo_peplink.svg'

class Template extends Component {
  componentDidMount() {
    document.body.style.background = '#FFF'
  }
  render() {
    const { title } = this.props
    return (
      <ContainerStyled
        style={{
          marginTop: process.env.REACT_APP_ENV !== ENV_PRODUCTION ? '26px' : '0',
        }}
      >
        <Row>
          <Col lg={12}>
            <NavbarBrandStyled href="/">
              <img src={logo} alt="Peplink" />
            </NavbarBrandStyled>
          </Col>
          <Col lg={12}>
            <CardStyled title={title}>
              {this.props.children}
              <FooterStyled>
                <small>© {new Date().getFullYear()} Peplink. All Rights Reserved.</small>
              </FooterStyled>
            </CardStyled>
          </Col>
        </Row>
      </ContainerStyled>
    )
  }
}

const ContainerStyled = styled(Container)`
  img {
    width: auto;
    height: 65px;
  }
`

const NavbarBrandStyled = styled(NavbarBrand)`
  padding: 0.5em 1em;
`

const CardStyled = styled(Card)`
  border-radius: 0px !important;
  background-color: #fafafa !important;
  padding: 1em 0.5em;
  .card-title {
    text-transform: capitalize !important;
    font-size: 16px !important;
  }
  .form-group {
    margin-bottom: 30px;
  }
`

const FooterStyled = styled.div`
  margin-top: 20px;
`

export default Template
