import React, { Component } from 'react'

import { Col, FormText, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { observe } from 'mobx'
import styled from 'styled-components'

import { formatOrderPayload } from 'helpers/Formatters'
import { ORDER_SOURCE } from 'definitions'
import { Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'

import { ProductPanelFunctions } from './ProductPanelFunctions'
import CoTermProductsTable from 'components/CoTerm/Edit/OrderForm/ProductsTable'
import CustomOptionsModal from './CustomOptionsModal'
import DiscountContainer from './DiscountContainer'
import FscIdModal from './FscIdModal'
import IccidNumbersModal from './IccidNumbersModal'
import OrderItemsModal from './OrderItemsModal'
import OrganizationNumbersModal from './OrganizationNumbersModal'
import ProductsTable from './ProductsTable'
import SerialNumbersModal from './SerialNumbersModal'

class ProductPanel extends Component {
  constructor(props) {
    super(props)
    const order = props.orderRepository.CRUD.data
    this.state = {
      isProductsModalOpen: false,
      isCustomOptionModalOpen: false,
      isSerialNumbersModalOpen: false,
      isIccidNumbersModalOpen: false,
      isOrganizationNumbersModalOpen: false,
      discountInd: (order.discount && order.discount.discountInd) || 'NA',
      selected: {},
    }
  }
  handleDeleteItem = item => ProductPanelFunctions.handleDeleteItem(item, this)
  handleDeleteCustomOption = (item, customOptionIndex) =>
    ProductPanelFunctions.handleDeleteCustomOption(item, customOptionIndex, this)
  handleDeleteDevice = (item, deviceIndex) => ProductPanelFunctions.handleDeleteDevice(item, deviceIndex, this)
  doDeleteItem = item => ProductPanelFunctions.doDeleteItem(item, this)
  handleQuantityChange = (value, item) => ProductPanelFunctions.handleQuantityChange(value, item, this)
  handleOrderItemSerialNumbers = (serialNumbers, proceed) => {
    this.showSfcCloudUnlimitedMessage(null)
    this.showEsimPlanMessage(null)
    ProductPanelFunctions.handleOrderItemSerialNumbers(serialNumbers, this, false, proceed)
  }
  handleOrderItemIccidNumbers = (iccidNumbers, proceed) => {
    ProductPanelFunctions.handleOrderItemIccidNumbers(iccidNumbers, this, false, proceed)
  }
  handleOrderItemOrganizationNumbers = organizationNumbers =>
    ProductPanelFunctions.handleOrderItemOrganizationNumbers(organizationNumbers, this)
  handleOrderItemFscId = fscId => ProductPanelFunctions.handleOrderItemFscId(fscId, this)
  handleSubmit = (item, updateShippingTotal) => ProductPanelFunctions.handleSubmit(item, updateShippingTotal, this)
  handleOrderItemChangeCustomDiscountedPrice = (val, index) =>
    ProductPanelFunctions.handleOrderItemChangeCustomDiscountedPrice(val, index, this)
  handleCustomOptionChangeCustomDiscountedPrice = (val, orderItemIndex, customOptionIndex) =>
    ProductPanelFunctions.handleCustomOptionChangeCustomDiscountedPrice(val, orderItemIndex, customOptionIndex, this)
  submitOrderItem = (product, callback = null, isCustomOptions = false) =>
    ProductPanelFunctions.submitOrderItem(product, callback, isCustomOptions, this)

  updateOrderData = (order, updateShippingTotal) =>
    ProductPanelFunctions.updateOrderData(order, updateShippingTotal, this)
  updateOrderItemsData = (orderItems, compute) => ProductPanelFunctions.updateOrderItemsData(orderItems, compute, this)
  showCustomOptions = async selected => {
    const { orderRepository, storeProductRepository } = this.props
    const order = orderRepository.CRUD.data
    const { data, errors } = await storeProductRepository.getCustomOptions(order.id, selected.product.id)

    let selectedProduct = { ...selected }
    if (data && !errors.length) {
      selectedProduct.product.customOptions = data
    }

    this.setState({ selected: selectedProduct })
    this.toggleCustomOptionsModal()
  }
  toggleProductModal = () => {
    this.setState({
      isProductsModalOpen: !this.state.isProductsModalOpen,
    })
  }
  toggleCustomOptionsModal = () => {
    this.setState({
      isCustomOptionModalOpen: !this.state.isCustomOptionModalOpen,
    })
  }
  toggleSerialNumbersModal = selected => {
    this.setState({
      selected,
      isSerialNumbersModalOpen: !this.state.isSerialNumbersModalOpen,
      sfcPlanDetails: null,
      esimDetails: null,
    })
  }
  toggleIccidNumbersModal = selected => {
    this.setState({
      selected,
      isIccidNumbersModalOpen: !this.state.isIccidNumbersModalOpen,
    })
  }
  toggleOrganizationNumbersModal = selected => {
    this.setState({
      selected,
      isOrganizationNumbersModalOpen: !this.state.isOrganizationNumbersModalOpen,
    })
  }
  toggleFscIdModal = selected => {
    this.setState({
      selected,
      isFscIdModalOpen: !this.state.isFscIdModalOpen,
    })
  }
  setDiscountInd = discountInd => {
    this.setState({
      discountInd: discountInd,
    })
  }
  showSfcCloudUnlimitedMessage = sfcPlanDetails => {
    this.setState({ sfcPlanDetails })
  }
  showEsimPlanMessage = esimDetails => {
    this.setState({ esimDetails })
  }
  renderStar = () => {
    return <span style={{ color: '#ffb81c' }}>★</span>
  }
  render() {
    const { discountInd } = this.state
    const { form, orderRepository, quotation, readOnly, orderSource } = this.props
    const orderDetails = orderRepository.CRUD.data
    const orderItems = orderDetails.orderItems || []
    const store = form.$('store').value

    const field = form.$('orderItems')
    const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
    const isCoTerm = [
      ORDER_SOURCE.COTERM_V1,
      ORDER_SOURCE.COTERM_V2,
      ORDER_SOURCE.COTERM_BACKEND,
      ORDER_SOURCE.COTERM_PARTNER_PAVILION,
      ORDER_SOURCE.COTERM_SIMULATOR_PARTNER_PAVILION,
    ].includes(orderSource)
    const quotationInd = orderDetails.quotationInd

    return (
      <Card
        title="Products"
        {...(!isCoTerm
          ? {
              titleActions: (
                <Visible toOrderProcessRole>
                  <div className="d-flex align-items-center">
                    <Checkbox
                      field={form.$('rebateMSRPInd')}
                      readOnly={readOnly}
                      disabled={readOnly}
                      className="mt-0 mr-3"
                      inputClassName="m-0"
                    />
                    <IconButton
                      disabled={readOnly}
                      color="dark"
                      className="mb-1"
                      outline
                      onClick={() => this.toggleProductModal()}
                    />
                  </div>
                </Visible>
              ),
            }
          : {})}
      >
        {(!isCoTerm || quotationInd) && (
          <ProductsTable
            quotation={quotation}
            readOnly={readOnly}
            form={form}
            discountInd={discountInd}
            toggleSerialNumbersModal={this.toggleSerialNumbersModal}
            toggleIccidNumbersModal={this.toggleIccidNumbersModal}
            toggleOrganizationNumbersModal={this.toggleOrganizationNumbersModal}
            toggleFscIdModal={this.toggleFscIdModal}
            showCustomOptions={this.showCustomOptions}
            handleQuantityChange={this.handleQuantityChange}
            handleOrderItemChangeCustomDiscountedPrice={this.handleOrderItemChangeCustomDiscountedPrice}
            handleCustomOptionChangeCustomDiscountedPrice={this.handleCustomOptionChangeCustomDiscountedPrice}
            handleDeleteItem={this.handleDeleteItem}
            handleDeleteCustomOption={this.handleDeleteCustomOption}
            renderStar={this.renderStar}
          />
        )}
        {isCoTerm && !quotationInd && (
          <CoTermProductsTable
            form={form}
            discountInd={discountInd}
            readOnly={readOnly}
            hideDelete
            handleOrderItemChangeCustomDiscountedPrice={this.handleOrderItemChangeCustomDiscountedPrice}
            handleDeleteDevice={this.handleDeleteDevice}
            renderStar={this.renderStar}
          />
        )}
        {hasError && (
          <FormText color="danger" style={{ marginTop: '-15px' }}>
            {field.error}
          </FormText>
        )}
        <Row>
          <Col sm={10}>
            <p>Total {orderDetails.totalProducts} product(s)</p>
          </Col>
          {orderDetails.hasOwnProperty('appliedCoupon') && (
            <Col sm={2} style={{ fontSize: 12 }}>
              {this.renderStar()} Coupon Code has been applied
            </Col>
          )}
        </Row>
        <PricingRuleField {...this.props} updateOrderData={this.updateOrderData} readOnly={readOnly} />
        <DiscountContainer
          readOnly={readOnly}
          readOnlyCoupon={readOnly}
          form={form}
          updateOrderData={this.updateOrderData}
          store={store}
          orderItems={orderItems}
          order={orderDetails}
          setDiscountInd={this.setDiscountInd}
          computeOrderSummary={this.props.computeOrderSummary}
        />
        <OrderItemsModal
          open={this.state.isProductsModalOpen}
          onClose={this.toggleProductModal}
          form={form}
          order={orderDetails}
          store={store}
          customer={form.$('customer').value}
          handleSelected={this.submitOrderItem}
        />
        <CustomOptionsModal
          open={this.state.isCustomOptionModalOpen}
          onClose={this.toggleCustomOptionsModal}
          form={form}
          order={orderDetails}
          store={store}
          handleSelected={this.submitOrderItem}
          selected={this.state.selected}
        />
        {this.state.isSerialNumbersModalOpen && (
          <SerialNumbersModal
            open
            onClose={this.toggleSerialNumbersModal}
            handleSubmit={this.handleOrderItemSerialNumbers}
            orderDetails={orderDetails}
            selectedOrderItem={this.state.selected}
            sfcPlanDetails={this.state.sfcPlanDetails}
            esimDetails={this.state.esimDetails}
            readOnly={readOnly}
          />
        )}
        {this.state.isIccidNumbersModalOpen && (
          <IccidNumbersModal
            open
            onClose={this.toggleIccidNumbersModal}
            handleSubmit={this.handleOrderItemIccidNumbers}
            orderDetails={orderDetails}
            selectedOrderItem={this.state.selected}
            readOnly={readOnly}
          />
        )}
        {this.state.isOrganizationNumbersModalOpen && (
          <OrganizationNumbersModal
            open
            orderDetails={orderDetails}
            selectedOrderItem={this.state.selected}
            readOnly={readOnly}
            onClose={this.toggleOrganizationNumbersModal}
            handleSubmit={this.handleOrderItemOrganizationNumbers}
          />
        )}
        {this.state.isFscIdModalOpen && (
          <FscIdModal
            open
            selectedOrderItem={this.state.selected}
            onClose={this.toggleFscIdModal}
            handleSubmit={this.handleOrderItemFscId}
          />
        )}
      </Card>
    )
  }
}

class PricingRuleField extends Component {
  constructor(props) {
    super(props)
    this.state = { options: [], isLoading: false }
    this.getPricingRuleOptions(props.orderRepository.CRUD.data)
  }
  componentDidMount() {
    observe(this.props.form.$('orderItems'), '$value', e => {
      if (!this.state.isLoading && !e.object.equals(e.newValue, e.oldValue)) {
        this.setState(
          {
            isLoading: true,
          },
          this.getPricingRuleOptions(
            formatOrderPayload({
              ...this.props.orderRepository.CRUD.data,
              ...this.props.form.values(),
              manualShippingFeeInd: false,
            })
          )
        )
      }
    })
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const orderDetails = nextProps.orderRepository.CRUD.data
    if (orderDetails.orderItemPricingRules) nextProps.form.$('pricingRules').set(orderDetails.orderItemPricingRules)
  }
  handlePricingRuleChange = vals => {
    const order = formatOrderPayload({
      ...this.props.orderRepository.CRUD.data,
      ...this.props.form.values(),
      manualShippingFeeInd: false,
    })
    order.orderItemPricingRules = vals
    this.props.orderItemRepository.applyPricingRule(order.id, { order, pricingRules: vals }, res => {
      this.props.updateOrderData(res.order)
      this.getPricingRuleOptions(res.order)
    })
  }
  getPricingRuleOptions = order => {
    this.props.orderRepository.getPricingRulesOptions(order, options => {
      this.setState({
        options,
        isLoading: false,
      })
    })
  }
  render() {
    const { options } = this.state
    const { form, readOnly } = this.props
    return (
      <ColPRStyled sm={12} className="price-rules">
        <ReactSelect
          disabled={readOnly}
          field={form.$('pricingRules')}
          customLabelKey={['label', 'id']}
          options={{
            isMulti: true,
            valueKey: 'id',
            labelKey: 'label',
            options: options,
          }}
          onChange={this.handlePricingRuleChange}
        />
      </ColPRStyled>
    )
  }
}

const ColPRStyled = styled(Col)`
  background: rgba(242, 242, 242, 0.8);
  margin-bottom: 15px;
  padding: 15px;
`

export default inject(
  'orderRepository',
  'orderItemRepository',
  'orderItemSerialNumberRepository',
  'orderItemIccidNumberRepository',
  'storeProductRepository'
)(observer(ProductPanel, {}))
