/* @flow */
import { Button, Col, Row } from 'reactstrap'
import { computed, decorate } from 'mobx'
import { COTERM_PATH } from 'definitions'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { formatDate } from 'helpers/Formatters'
import { ORDER_SOURCE } from 'definitions'
import { Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import CommonSelect from 'components/Common/CommonSelect'
import ContentContainer from 'components/Common/ContentContainer'
import DatePicker from 'components/Common/Form/DatePicker'
import ErrorsList from 'components/Common/ErrorsList'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Products from './Products'
import ProductsV2 from './ProductsV2'
import React, { Component } from 'react'
import ReactSelect from 'components/Common/Form/ReactSelect'

class CoTermForm extends Component {
  selectedCoTermProducts = []
  defaultTargetDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  allowLessThan1YearDate = new Date(new Date().setDate(new Date().getDate() + 1))

  constructor(props) {
    super(props)

    const rules = 'required'
    const fields = {
      orderType: {
        label: 'Co-Term Order Type',
        type: 'select',
        validators: [this.validateOrderType],
      },
      customer: {
        label: 'Customer Name',
        rules,
      },
      type: {
        label: 'Co-Term Type',
        type: 'select',
      },
      targetDate: {
        label: 'Target Date',
        type: 'date',
        rules,
        value: this.defaultTargetDate,
      },
      serialNumbers: {
        type: 'textarea',
      },
      poNumber: {
        label: 'PO No',
      },
      skipSNWarrantyCheck: {
        type: 'checkbox',
        label: 'Skip SN Warranty Check',
      },
      allowLessThan1YearInd: {
        type: 'checkbox',
        label: 'Select < 1-Year Date',
      },
      coTermCustomerRemarks: {
        type: 'textarea',
        label: 'Co-Term Customer Remarks',
      },
    }

    this.form = new Form({ fields }, { name: 'CoTermForm', handleSubmit: this.handleSubmit })

    this.state = {
      errors: [],
      next: false,
      promptOnExit: false,
      submitting: false,
      disableSubmit: false,
    }
  }

  get formValues() {
    return this.form.values()
  }

  validateOrderType = ({ _, field }) => {
    if (!field.value) {
      return [false, 'The field is required.']
    } else if (field.value?.value === ORDER_SOURCE.COTERM_SIMULATOR) {
      return [false, `${field.label} is not yet available`]
    }

    return [true]
  }

  handleNext = () => {
    const selectedOrderType = this.form.$('orderType').value
    const isCoTermV2 = selectedOrderType?.value === ORDER_SOURCE.COTERM_V2

    this.form.$('serialNumbers').set('rules', 'required')
    if (!isCoTermV2) {
      this.form.$('type').set('rules', 'required')
    } else {
      this.form.$('type').set('rules', null)
    }

    this.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        if (isCoTermV2) {
          this.form.$('serialNumbers').set('rules', null)
          this.form.$('type').set('rules', null)
        }

        this.setState({ next: true })
        if (isCoTermV2) {
          this.productsV2Ref && this.productsV2Ref.fetchTable()
        }
      }
    })
  }

  handleSubmit = () => {
    const { coTermRepository, history } = this.props
    const { orderType, customer, targetDate, poNumber, allowLessThan1YearInd, coTermCustomerRemarks } =
      this.form.values()

    this.setState({ promptOnExit: true, submitting: true, errors: [] })
    coTermRepository.CRUD.loading = true

    coTermRepository
      .create({
        orderSource: orderType.value,
        customer,
        targetDate: formatDate(targetDate, 'YYYY-MM-DD'),
        allowLessThan1YearInd,
        selectedCoTermProducts: this.selectedCoTermProducts,
        poNumber,
        coTermCustomerRemarks,
      })
      .then(({ data, errors, errorCode }) => {
        if (errors.length) {
          // eslint-disable-next-line no-throw-literal
          throw { errors, errorCode }
        } else {
          this.setState({ promptOnExit: false })
          history.push(`${COTERM_PATH}/${data.id}?edit=true`)
        }
      })
      .catch(result => {
        this.setState({ promptOnExit: false })
        const fields = ['selectedCoTermProducts']
        const errors = []
        result.errors.forEach(error => {
          if (error.fieldName) {
            fields.forEach(block => {
              if (error.fieldName.indexOf(block) === -1) {
                this.form.$(error.fieldName).invalidate(error.message)
              } else {
                errors.push(error)
              }
            })
          } else {
            errors.push(error)
          }
        })
        this.setState({
          errors,
        })
      })
      .finally(() => {
        this.setState({ submitting: false })
        coTermRepository.CRUD.loading = false
      })
  }

  handleCancel = () => this.props.history.push(COTERM_PATH)

  handleSelect = (selectedCoTermProducts, disableSubmit) => {
    this.selectedCoTermProducts = selectedCoTermProducts
    this.setState({ disableSubmit })
  }

  handleCoTermOrderTypeLoaded = coTermOrderTypes => {
    if (coTermOrderTypes?.length && !this.form.$('orderType').value) {
      const defaultValue = coTermOrderTypes.find(item => item.value === ORDER_SOURCE.CO_TERM)
      if (defaultValue) {
        this.form.$('orderType').set(defaultValue)
      }
    }
  }

  handleCoTermOrderTypeOnBeforeChange = selected => {
    if (this.form.$('orderType').value?.value !== selected?.value) {
      const { coTermProductRepository } = this.props
      coTermProductRepository.clearTableData()
      this.setState({ disableSubmit: false })
    }
  }

  handleCoTermOrderTypeOnChange = selected => {
    const isCoTermV2 = selected?.value === ORDER_SOURCE.COTERM_V2

    if (isCoTermV2) {
      this.form.$('type').set('rules', null)
      this.form.$('type').resetValidation()
    }
  }

  handleFormChange = () => {
    const selectedOrderType = this.form.$('orderType').value
    const isCoTermV2 = selectedOrderType?.value === ORDER_SOURCE.COTERM_V2

    if (isCoTermV2) {
      this.productsV2Ref && this.productsV2Ref.fetchTable()
    } else {
      this.productsV1Ref && this.productsV1Ref.fetchTable()
    }
  }

  handleAllowLessThan1Year = () => {
    const targetDate = this.form.$('targetDate').value
    const allowLessThan1YearInd = this.form.$('allowLessThan1YearInd').value

    if (!allowLessThan1YearInd && targetDate < this.defaultTargetDate) {
      this.form.$('targetDate').set(this.defaultTargetDate)
    }
    this.handleFormChange()
  }

  render() {
    const {
      customerRepository: { customerReactSelectSearch },
      coTermProductRepository: {
        Table: { errors: productErrors = [], loading: productsLoading },
      },
      readOnly,
    } = this.props

    const { errors, next, submitting, disableSubmit: disableSubmitState } = this.state
    const selectedCustomer = this.form.$('customer').value
    const selectedOrderType = this.form.$('orderType').value
    const allowLessThan1YearInd = this.form.$('allowLessThan1YearInd').value

    const isCoTermV2 = selectedOrderType?.value === ORDER_SOURCE.COTERM_V2
    const disableSubmit = productsLoading || submitting || disableSubmitState

    return (
      <ContentContainer
        title={`Create New Co-Term Quotation`}
        promptOnExit={this.state.promptOnExit}
        promptOnExitIsConfirm={false}
      >
        <FormContainer
          plain
          onSubmit={event => {
            // needs to explicitly remove validation because
            // for some reason required rules still persists even if the rules are set to null prior to this
            if (isCoTermV2) {
              this.form.$('serialNumbers').set('rules', null)
            }

            return this.form.onSubmit(event, { onSuccess: this.handleSubmit, onError: this.handleError })
          }}
        >
          {!!errors.length && <ErrorsList errors={errors} />}
          {!!productErrors.length && <ErrorsList errors={productErrors} />}

          <Row>
            <Col>
              <Card title="Account Information" className="mb-3">
                <Row>
                  <Col xs="4">
                    <CommonSelect
                      field={this.form.$('orderType')}
                      collectionName="collections/coTermOrderType"
                      onCollectionLoaded={this.handleCoTermOrderTypeLoaded}
                      disabled={productsLoading}
                      options={{
                        isClearable: false,
                      }}
                      onBeforeChange={selected => this.handleCoTermOrderTypeOnBeforeChange(selected)}
                      onChange={selected => this.handleCoTermOrderTypeOnChange(selected)}
                    />
                  </Col>
                  <Col xs="4">
                    <ReactSelect
                      field={this.form.$('customer')}
                      serverSide
                      search={payload =>
                        customerReactSelectSearch({
                          ...payload,
                          status: 'ACTIVE',
                        })
                      }
                      options={{
                        valueKey: 'id',
                        labelKey: 'customerLabel',
                        options: selectedCustomer ? [selectedCustomer] : [],
                        value: selectedCustomer ? selectedCustomer : undefined,
                        defaultOptions: true,
                        isClearable: false,
                        isDisabled: productsLoading,
                      }}
                      onChange={this.handleFormChange}
                      readOnly={readOnly}
                      showTooltip
                    />
                  </Col>
                  <Col xs="4">
                    <CommonSelect
                      field={this.form.$('type')}
                      collectionName="coTermTypes"
                      disabled={isCoTermV2 || productsLoading}
                      {...(isCoTermV2 ? {} : { onChange: this.handleFormChange })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <DatePicker
                      field={this.form.$('targetDate')}
                      label={
                        <div className="d-flex align-items-center">
                          <div>{this.form.$('targetDate').label}</div>
                          <Checkbox
                            field={this.form.$('allowLessThan1YearInd')}
                            className="ml-auto mt-0"
                            inputClassName="m-0"
                            onChange={this.handleAllowLessThan1Year}
                          />
                        </div>
                      }
                      disabled={productsLoading}
                      options={{
                        showMonthDropdown: true,
                        showYearDropdown: true,
                        minDate: allowLessThan1YearInd ? this.allowLessThan1YearDate : this.defaultTargetDate,
                      }}
                      labelClassName="w-100"
                      onChange={this.handleFormChange}
                    />
                    <Input field={this.form.$('poNumber')} />
                  </Col>
                  <Col xs="8">
                    <Input field={this.form.$('coTermCustomerRemarks')} rows="4" style={{ minHeight: '108px' }} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <CoTermProductsSectionStyled>
            <div className="serial-numbers-section">
              <Card title="Serial Numbers" className="mb-3">
                <div className="form-group">
                  <label htmlFor="">Input 1 serial number each row</label>
                  <Input
                    field={this.form.$('serialNumbers')}
                    rows="14"
                    disabled={productsLoading}
                    onChange={this.handleFormChange}
                  />
                </div>
                <Checkbox
                  field={this.form.$('skipSNWarrantyCheck')}
                  className="py-1"
                  onChange={this.handleFormChange}
                />
              </Card>
              <div className="d-flex justify-content-end">
                {(!next || isCoTermV2) && (
                  <Visible toAppAdminRole>
                    <Button
                      onClick={this.handleNext}
                      type="button"
                      color="primary"
                      className="d-flex"
                      disabled={productsLoading}
                    >
                      {isCoTermV2 ? 'Add to List' : 'Next'}
                    </Button>
                  </Visible>
                )}
              </div>
            </div>
            {next && (
              <div className="products-table-section">
                {!isCoTermV2 && (
                  <Products
                    key="coTerm"
                    ref={ref => (this.productsV1Ref = ref)}
                    formValues={this.formValues}
                    form={this.form}
                    onSelect={this.handleSelect}
                  />
                )}
                {!!isCoTermV2 && (
                  <ProductsV2
                    key="coTermV2"
                    ref={ref => (this.productsV2Ref = ref)}
                    form={this.form}
                    onSelect={this.handleSelect}
                  />
                )}

                <div className="d-flex justify-content-end mb-3">
                  <Visible toAppAdminRole>
                    <FormActions
                      loading={submitting}
                      loadingMessage="Please wait..."
                      confirmLabel="Create Quotation"
                      hideCancel
                      disabled={disableSubmit}
                    />
                  </Visible>
                </div>
              </div>
            )}
          </CoTermProductsSectionStyled>
        </FormContainer>
      </ContentContainer>
    )
  }
}

const CoTermProductsSectionStyled = styled.div`
  &&& {
    display: flex;
    .serial-numbers-section {
      width: 250px;
    }
    .products-table-section {
      flex-grow: 1;
      flex-basis: 0;
      overflow-x: hidden;
      padding-left: 16px;
    }
  }
`

export default withRouter(
  inject(
    'coTermRepository',
    'customerRepository',
    'commonRepository',
    'coTermProductRepository'
  )(
    decorate(observer(CoTermForm), {
      formValues: computed,
    })
  )
)
