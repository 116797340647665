import React, { Component } from 'react'

import { Button, Col, Row } from 'reactstrap'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { ACTIVE_STATUS } from 'definitions'
import { Visible } from 'helpers/Auth'
import BlockMessage from 'components/Common/BlockMessage'
import Card from 'components/Common/Card'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ToasterNotify from 'helpers/ToasterNotify'

class BillingShippingPanel extends Component {
  constructor(props) {
    super(props)
    this.props.commonRepository.getCommon('countries', 'countries', {})
  }
  componentDidMount() {
    const { form, customer } = this.props
    const billingContact = form.$('billingAddress').value
    const shippingContact = form.$('shippingAddress').value
    if (billingContact && billingContact.sanctioned) {
      ToasterNotify.alert({
        title: 'Warning',
        message: `Billing Contact: ${billingContact.firstName} ${billingContact.lastName} is sanctioned. `,
        color: 'error',
        backdrop: false,
      })
    }

    if (shippingContact && shippingContact.sanctioned) {
      ToasterNotify.alert({
        title: 'Warning',
        message: `Shipping Contact: ${shippingContact.firstName} ${shippingContact.lastName} is sanctioned. `,
        color: 'error',
        backdrop: false,
      })
    }

    if (shippingContact && shippingContact.country.countryDisableDirectShipment) {
      ToasterNotify.alert({
        title: 'Warning',
        message: `Shipping Country: ${shippingContact.country.label} is not allowed for Direct Shipment. `,
        color: 'error',
        backdrop: false,
      })
    }
    if (customer) {
      const billingCompany = form.$('billingAddress.company')
      const shippingCompany = form.$('shippingAddress.company')
      if (!billingCompany.value) billingCompany.set(customer.name)
      if (!shippingCompany.value) shippingCompany.set(customer.name)
    }
  }
  toggleBillingContact = values => {
    const {
      firstName = '',
      middleName = '',
      lastName = '',
      email = '',
      tel = '',
      sanctioned = false,
    } = values ? values : {}
    this.props.form.$('billingAddress').set({
      firstName,
      middleName,
      lastName,
      email,
      telNo: tel,
      sanctioned,
    })
    if (sanctioned) {
      ToasterNotify.alert({
        title: 'Warning',
        message: `Billing Contact: ${firstName} ${lastName} is sanctioned. `,
        color: 'error',
        backdrop: false,
      })
    } else {
      ToasterNotify.close()
    }
  }
  toggleBillingAddress = values => {
    const {
      company = '',
      streetAddress1 = '',
      streetAddress2 = '',
      city = '',
      zipCode = '',
      country = null,
      state = '',
    } = values ? values : {}
    this.props.form.$('billingAddress').set({
      company: company ? company : this.props.customer?.name,
      streetAddress1,
      streetAddress2,
      city,
      zipCode,
      country,
      state,
    })

    if (country) {
      this.props.updateBankAccount(country.id)
    }
  }
  toggleShippingCotact = values => {
    const {
      firstName = '',
      middleName = '',
      lastName = '',
      email = '',
      tel = '',
      sanctioned = false,
    } = values ? values : {}
    this.props.form.$('shippingAddress').set({
      firstName,
      middleName,
      lastName,
      email,
      telNo: tel,
      sanctioned,
    })
    if (sanctioned) {
      ToasterNotify.alert({
        title: 'Warning',
        message: `Shipping Contact: ${firstName} ${lastName} is sanctioned. `,
        color: 'error',
        backdrop: false,
      })
    } else {
      ToasterNotify.close()
    }
  }
  toggleShippingAddress = values => {
    const {
      company = '',
      streetAddress1 = '',
      streetAddress2 = '',
      city = '',
      zipCode = '',
      country = null,
      state = '',
    } = values ? values : {}
    const oldCountry = this.props.form.$('shippingAddress.country').value
    this.props.form.$('shippingAddress').set({
      company: company ? company : this.props.customer.name,
      streetAddress1,
      streetAddress2,
      city,
      zipCode,
      country,
      state,
    })
    if (oldCountry && country && oldCountry.value !== country.value) {
      this.props.computeOrderSummary(true)
    }
  }
  copyBilling = () => {
    const oldCountry = this.props.form.$('shippingAddress.country').value
    const billing = this.props.form.$('billingAddress').value
    const selectedCustomerBillingContact = this.props.form.$('contactBillingSelection').value
    const selectedCustomerBillingAddress = this.props.form.$('addressBillingSelection').value

    this.props.form.$('shippingAddress').set(billing)
    selectedCustomerBillingContact &&
      this.props.form.$('contactShippingSelection').set({ ...selectedCustomerBillingContact })
    selectedCustomerBillingAddress &&
      this.props.form.$('addressShippingSelection').set({ ...selectedCustomerBillingAddress })

    if (
      selectedCustomerBillingAddress &&
      oldCountry &&
      oldCountry.value !== selectedCustomerBillingAddress.country.value
    ) {
      this.props.computeOrderSummary(true)
    }
  }
  handleBillingCountryChange = (value, field) => {
    field.set(value)
    field.validate()
    if (value) {
      this.props.updateBankAccount(value.id)
    }
    this.props.computeOrderSummary()
  }
  handleShippingCountryChange = (value, field) => {
    if (value && value.countryDisableDirectShipment) {
      ToasterNotify.alert({
        title: 'Warning',
        message: `Shipping Country: ${value.label} is not allowed for Direct Shipment. `,
        color: 'error',
        backdrop: false,
      })
    } else {
      ToasterNotify.close()
    }
    field.set(value)
    field.validate()
    this.props.computeOrderSummary(true)
  }
  handleWarningText = fieldName => {
    const warningMaxLengthWarning =
      '*Please note that the courier might only be able to display the first 35 characters.'
    const field = this.props.form.$(fieldName)
    return field.value && field.value.length > 35 && warningMaxLengthWarning
  }
  render() {
    const {
      form,
      commonRepository,
      customerContactRepository,
      customerAddressRepository,
      readOnly,
      isShippingApplicable,
    } = this.props
    const { countries } = commonRepository.Common
    const selectedCustomer = form.$('customer').value
    return (
      <BillingShippingPanelStyled>
        <Col xs="6">
          <Card title="Billing Information">
            <ReactSelect
              readOnly={readOnly}
              field={form.$('contactBillingSelection')}
              customLabelKey={['name', 'id']}
              serverSide
              search={payload =>
                customerContactRepository.reactSelectSearch({ ...payload, status: ACTIVE_STATUS }, selectedCustomer.id)
              }
              options={{
                isDisabled: !selectedCustomer,
                valueKey: 'id',
                labelKey: 'firstName',
                formatOptionLabel: obj => `${obj.firstName || ''} ${obj.lastName || ''}`,
                defaultOptions: true,
              }}
              onChange={this.toggleBillingContact}
            />
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('billingAddress.firstName')} />
              <Input readOnly={readOnly} field={form.$('billingAddress.middleName')} />
              <Input readOnly={readOnly} field={form.$('billingAddress.lastName')} />
            </div>
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('billingAddress.email')} />
              <Input readOnly={readOnly} field={form.$('billingAddress.telNo')} />
            </div>
            <ReactSelect
              readOnly={readOnly}
              field={form.$('addressBillingSelection')}
              customLabelKey={['name', 'id']}
              serverSide
              search={payload => customerAddressRepository.reactSelectSearch(payload, selectedCustomer.id)}
              options={{
                isDisabled: !selectedCustomer,
                valueKey: 'id',
                labelKey: 'company',
                formatOptionLabel: obj => `${obj.country ? obj.country.label : ''}, ${obj.state}, ${obj.city}`,
                defaultOptions: true,
              }}
              onChange={this.toggleBillingAddress}
            />
            <Input
              readOnly={readOnly}
              field={form.$('billingAddress.company')}
              warningText={this.handleWarningText('billingAddress.company')}
            />
            <Input readOnly={readOnly} field={form.$('billingAddress.streetAddress1')} />
            <Input readOnly={readOnly} field={form.$('billingAddress.streetAddress2')} />
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('billingAddress.city')} />
              <Input readOnly={readOnly} field={form.$('billingAddress.zipCode')} />
            </div>
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('billingAddress.state')} className="w-50" />
              <ReactSelect
                readOnly={readOnly}
                field={form.$('billingAddress.country')}
                options={{
                  value: form.$('billingAddress.country').value,
                  options: countries.data,
                  onChange: vals => this.handleBillingCountryChange(vals, form.$('billingAddress.country')),
                  isClearable: false,
                }}
                innerClassName="react-select"
                className="w-50"
              />
            </div>
          </Card>
        </Col>
        <Col xs="6" style={{ position: 'relative' }}>
          {!!isShippingApplicable && <BlockMessage message="Shipping address selection is not applicable" />}
          <Card
            title="Shipping Information"
            titleActions={
              <Visible toOrderProcessRole>
                <Button disabled={readOnly} size="xs" outline color="dark" onClick={this.copyBilling}>
                  Copy Billing Information
                </Button>
              </Visible>
            }
          >
            <ReactSelect
              readOnly={readOnly}
              field={form.$('contactShippingSelection')}
              customLabelKey={['name', 'id']}
              serverSide
              search={payload =>
                customerContactRepository.reactSelectSearch({ ...payload, status: ACTIVE_STATUS }, selectedCustomer.id)
              }
              options={{
                isDisabled: !selectedCustomer,
                valueKey: 'id',
                labelKey: 'firstName',
                formatOptionLabel: obj => `${obj.firstName} ${obj.lastName}`,
                defaultOptions: true,
              }}
              onChange={this.toggleShippingCotact}
            />
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('shippingAddress.firstName')} />
              <Input readOnly={readOnly} field={form.$('shippingAddress.middleName')} />
              <Input readOnly={readOnly} field={form.$('shippingAddress.lastName')} />
            </div>
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('shippingAddress.email')} />
              <Input readOnly={readOnly} field={form.$('shippingAddress.telNo')} />
            </div>
            <ReactSelect
              readOnly={readOnly}
              field={form.$('addressShippingSelection')}
              customLabelKey={['name', 'id']}
              serverSide
              search={payload => customerAddressRepository.reactSelectSearch(payload, selectedCustomer.id)}
              options={{
                isDisabled: !selectedCustomer,
                valueKey: 'id',
                labelKey: 'company',
                formatOptionLabel: obj => `${obj.country ? obj.country.label : ''}, ${obj.state}, ${obj.city}`,
                defaultOptions: true,
              }}
              onChange={this.toggleShippingAddress}
            />
            <Input
              readOnly={readOnly}
              field={form.$('shippingAddress.company')}
              warningText={this.handleWarningText('shippingAddress.company')}
            />
            <Input readOnly={readOnly} field={form.$('shippingAddress.streetAddress1')} />
            <Input readOnly={readOnly} field={form.$('shippingAddress.streetAddress2')} />
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('shippingAddress.city')} />
              <Input readOnly={readOnly} field={form.$('shippingAddress.zipCode')} />
            </div>
            <div className="grouped">
              <Input readOnly={readOnly} field={form.$('shippingAddress.state')} className="w-50" />
              <ReactSelect
                readOnly={readOnly}
                field={form.$('shippingAddress.country')}
                options={{
                  value: form.$('shippingAddress.country').value,
                  options: countries.data,
                  onChange: vals => this.handleShippingCountryChange(vals, form.$('shippingAddress.country')),
                  isClearable: false,
                }}
                className="w-50"
              />
            </div>
          </Card>
        </Col>
      </BillingShippingPanelStyled>
    )
  }
}

const BillingShippingPanelStyled = styled(Row)`
  .card-body {
    padding-bottom: 32px;
    .grouped {
      display: flex;
      .form-group {
        flex-grow: 1;
        &:focus-within {
          z-index: 2;
        }
      }
    }
  }
`

export default observer(BillingShippingPanel)
