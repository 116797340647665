import React from 'react'

import { Button, Input, UncontrolledTooltip } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import styled from 'styled-components'

import { formatCustomerCurrency, formatDecimalValByCustomer, formatNumber } from 'helpers/Formatters'
import {
  PRODUCT_NATURE_VIRTUAL,
  PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
  PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
  PRODUCTS_PATH,
} from 'definitions'
import IconButton from 'components/Common/Buttons/IconButton'
import QuantityField from 'components/Common/Form/QuantityField'
import ReactTable from 'components/Common/ReactTable'
import TextDecoration from 'components/Common/TextDecoration'

class ProductsTable extends React.Component {
  renderSerialNumbers = item => {
    const { toggleSerialNumbersModal, readOnly } = this.props

    if (item.product.product.productNature && item.product.product.productNature.value === PRODUCT_NATURE_VIRTUAL) {
      if (!item.serialNumbers?.length) {
        return (
          <CellStyled>
            <Button
              color="light"
              size="xs"
              className={cx('d-flex align-items-center with-icon', !item.iccidNumbers?.length && 'text-danger')}
              onClick={() => {
                toggleSerialNumbersModal(item)
              }}
              disabled={readOnly}
            >
              <i className="icon ion-md-warning small" style={{ marginRight: '5px' }} />
              Please Configure
            </Button>
          </CellStyled>
        )
      } else {
        return (
          <CellStyled>
            <Button
              color="light"
              size="xs"
              className="d-flex align-items-center"
              onClick={() => {
                toggleSerialNumbersModal(item)
              }}
            >
              Configured
            </Button>
          </CellStyled>
        )
      }
    } else {
      return (
        <CellStyled>
          <div className="cell-sn">N/A</div>
        </CellStyled>
      )
    }
  }

  renderIccidNumbers = item => {
    const { toggleIccidNumbersModal, readOnly } = this.props

    if (
      item.iccidBasedInd &&
      item.product.product.productNature &&
      item.product.product.productNature.value === PRODUCT_NATURE_VIRTUAL
    ) {
      if (!item.iccidNumbers?.length) {
        return (
          <CellStyled>
            <Button
              color="light"
              size="xs"
              className={cx('d-flex align-items-center with-icon', !item.serialNumbers?.length && 'text-danger')}
              onClick={() => {
                toggleIccidNumbersModal(item)
              }}
              disabled={readOnly}
            >
              <i className="icon ion-md-warning small" style={{ marginRight: '5px' }} />
              Please Configure
            </Button>
          </CellStyled>
        )
      } else {
        return (
          <CellStyled>
            <Button
              color="light"
              size="xs"
              className="d-flex align-items-center"
              onClick={() => {
                toggleIccidNumbersModal(item)
              }}
            >
              Configured
            </Button>
          </CellStyled>
        )
      }
    } else {
      return (
        <CellStyled>
          <div className="cell-sn">N/A</div>
        </CellStyled>
      )
    }
  }

  renderOrganizationNumbers = item => {
    const { toggleOrganizationNumbersModal } = this.props

    if (
      item.product.product.productNature &&
      item.product.product.productNature.value === PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID
    ) {
      const hasOrganizationNumbers = !!item.ic2Organizations?.length

      return (
        <CellStyled>
          <Button
            color="light"
            size="xs"
            className={cx('d-flex align-items-center', !hasOrganizationNumbers && 'text-danger')}
            onClick={() => {
              toggleOrganizationNumbersModal(item)
            }}
          >
            {!hasOrganizationNumbers && <i className="icon ion-md-warning small" style={{ marginRight: '5px' }} />}
            {hasOrganizationNumbers ? 'Configured' : 'Please Configure'}
          </Button>
        </CellStyled>
      )
    } else {
      return (
        <CellStyled>
          <div className="cell-sn">N/A</div>
        </CellStyled>
      )
    }
  }

  renderFscId = orderItem => {
    const { toggleFscIdModal, readOnly } = this.props

    if (
      orderItem.product.product.productNature &&
      orderItem.product.product.productNature.value === PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID
    ) {
      const hasFscId = !!orderItem.fscId

      return (
        <CellStyled>
          {hasFscId ? (
            <div className={cx(!readOnly && 'with-icon-button')}>
              {orderItem.fscId}
              {!readOnly && (
                <IconButton
                  icon="ios-build"
                  className="p-1"
                  style={{ marginTop: '-4px' }}
                  title="Configure FSC ID"
                  onClick={() => toggleFscIdModal(orderItem)}
                  iconSize="small"
                />
              )}
            </div>
          ) : (
            <Button
              color="light"
              size="xs"
              className={cx('d-flex align-items-center', !hasFscId && 'text-danger')}
              onClick={() => toggleFscIdModal(orderItem)}
              disabled={readOnly}
            >
              {'Please Configure'} <i className="icon ion-md-warning small" style={{ marginRight: '5px' }} />
            </Button>
          )}
        </CellStyled>
      )
    } else {
      return (
        <CellStyled>
          <div className="cell-sn">N/A</div>
        </CellStyled>
      )
    }
  }

  handleRebateMsrpChange = (tablOrderItem, value) => {
    const { form } = this.props
    const orderItems = form.$('orderItems').value
    const orderItem = orderItems.find(item => item.id === tablOrderItem.id)

    const rebateMSRP = value ? parseInt(value, 10) : value
    tablOrderItem.rebateMSRP = rebateMSRP
    if (orderItem) {
      orderItem.rebateMSRP = rebateMSRP
      form.$('orderItems').set(orderItems)
    }
  }

  render() {
    const {
      form,
      orderRepository,
      quotation,
      readOnly,
      discountInd,
      showCustomOptions,
      handleQuantityChange,
      handleOrderItemChangeCustomDiscountedPrice,
      handleCustomOptionChangeCustomDiscountedPrice,
      handleDeleteItem,
      handleDeleteCustomOption,
      renderStar,
    } = this.props

    const customer = form.$('customer').value
    const rebateMSRPInd = form.$('rebateMSRPInd').value

    return (
      <ReactTable
        className="no-hover"
        columns={[
          {
            Header: 'Product Code',
            accessor: 'product.product.productCode',
            sortable: false,
            width: 150,
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  <div className="ellipsis">
                    <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`}>
                      {original.product.product.productCode}
                    </Link>
                  </div>
                  {original.customOptionValues &&
                    original.customOptionValues.map(co => (
                      <div key={co.productCode} className="ellipsis">
                        {co.productCode}
                      </div>
                    ))}
                </CellStyled>
              )
            },
          },
          {
            Header: 'Product Name',
            accessor: 'product.product.name',
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  <div className="ellipsis">
                    <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`} title={original.product.product.name}>
                      {original.product.product.name}
                    </Link>{' '}
                    {!readOnly &&
                      ((original.product.customOptions && !!original.product.customOptions.length) ||
                        (original.product.product &&
                          original.product.product.productType &&
                          original.product.product.productType.value === 'configurable product')) && (
                        <COButtonStyled
                          color="primary"
                          size="xs"
                          onClick={() => showCustomOptions(original)}
                          type="button"
                        >
                          <i className="icon ion-md-add tiny" />
                        </COButtonStyled>
                      )}
                  </div>
                  {original.attributes &&
                    !!original.attributes.length &&
                    original.attributes.map(attr => (
                      <div key={attr.attributeName} className="text-capitalize pb-0 pt-0">
                        <strong>{attr.attributeName}</strong> : {attr.attributeLabel || attr.attributeValue}
                      </div>
                    ))}
                  {original.customOptionValues &&
                    original.customOptionValues.map(co => (
                      <div key={co.productCode} title={co.title} className="ellipsis">
                        + {co.title}
                      </div>
                    ))}
                  {!!original.ic2Organizations &&
                    original.ic2Organizations.map(item => (
                      <div key={item.org_code} title={item.name || item.org_code} className="ellipsis">
                        + {`${item.org_code}${item.name ? ` (${item.name})` : ''}`}
                      </div>
                    ))}
                </CellStyled>
              )
            },
          },
          {
            Header: 'Serial Number',
            accessor: 'serialNumbers',
            headerClassName: 'text-left pl-2',
            sortable: false,
            width: 150,
            Cell: ({ original }) => this.renderSerialNumbers(original),
          },
          {
            Header: 'ICCID Number',
            accessor: 'iccidNumbers',
            headerClassName: 'text-left pl-2',
            sortable: false,
            width: 150,
            Cell: ({ original }) => this.renderIccidNumbers(original),
          },
          ...(!quotation
            ? [
                {
                  Header: (
                    <ColumnContentWithIconStyled>
                      <span id="columnOrgCode">
                        Org Code <i className="icon ion-md-alert text-default ml-1"></i>
                      </span>
                      <UncontrolledTooltip target="columnOrgCode" placement="top">
                        SFC Pooled Only
                      </UncontrolledTooltip>
                    </ColumnContentWithIconStyled>
                  ),
                  accessor: 'ic2Organizations',
                  headerClassName: 'text-left',
                  sortable: false,
                  width: 150,
                  Cell: ({ original }) => this.renderOrganizationNumbers(original),
                },
              ]
            : []),
          {
            Header: (
              <ColumnContentWithIconStyled>
                <span id="columnFscId">
                  FSC ID <i className="icon ion-md-alert text-default ml-1"></i>
                </span>
                <UncontrolledTooltip target="columnFscId" placement="top">
                  FusionSim Enterprise Only
                </UncontrolledTooltip>
              </ColumnContentWithIconStyled>
            ),
            accessor: 'fscId',
            headerClassName: 'text-left pl-2',
            className: 'text-left cell-qty',
            sortable: false,
            width: 150,
            Cell: ({ original }) => this.renderFscId(original),
          },
          {
            Header: 'Quantity',
            accessor: 'quantity',
            headerClassName: 'd-flex justify-content-center',
            className: 'text-center cell-qty',
            sortable: false,
            width: 100,
            Cell: ({ row, original }) => {
              return (
                <CellStyled>
                  <QuantityField
                    readOnly={
                      readOnly ||
                      [PRODUCT_NATURE_VIRTUAL, PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID].includes(
                        original?.product?.product?.productNature?.value
                      )
                    }
                    value={row.quantity}
                    onChange={newVal => handleQuantityChange(newVal, original)}
                  />
                </CellStyled>
              )
            },
          },
          ...(rebateMSRPInd
            ? [
                {
                  Header: 'Rebate MSRP',
                  accessor: 'rebateMSRP',
                  headerClassName: 'd-flex justify-content-center',
                  className: 'd-flex justify-content-center',
                  sortable: false,
                  width: 120,
                  Cell: ({ original, value }) => {
                    if (readOnly) {
                      return value ? formatNumber(value, 0) : 'N/A'
                    } else {
                      return (
                        <RebateMsrpInputStyled
                          className="form-control"
                          readOnly={readOnly}
                          defaultValue={value}
                          maxLength="10"
                          onBeforeInput={event => {
                            // allow up to 10 digits only (0-9)
                            if (!/^[0-9]{0,10}$/.test(event.data)) {
                              event.preventDefault()
                            }
                          }}
                          onChange={event => this.handleRebateMsrpChange(original, event.target.value)}
                          onBlur={event => {
                            if (event.target.value) {
                              // remove trailing zeroes if any
                              event.target.value = parseInt(event.target.value, 10)
                            }
                          }}
                        />
                      )
                    }
                  },
                },
              ]
            : []),
          {
            Header: 'Listed Price',
            accessor: 'listedPrice',
            headerClassName: 'text-right',
            className: 'text-right',
            sortable: false,
            width: 120,
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  {original.listedPrice ? (
                    <div>
                      <TextDecoration
                        decoration={
                          original.hasOwnProperty('salePrice') || original.hasOwnProperty('discountedPrice')
                            ? 'line-through'
                            : null
                        }
                      >
                        <div>{formatCustomerCurrency(original.listedPrice, customer)}</div>
                      </TextDecoration>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                  {original.customOptionValues &&
                    original.customOptionValues.map(co => (
                      <div key={co.productCode}>
                        {co.listedPrice ? (
                          <TextDecoration
                            decoration={
                              co.hasOwnProperty('salePrice') || co.hasOwnProperty('discountedPrice')
                                ? 'line-through'
                                : null
                            }
                          >
                            <div>{formatCustomerCurrency(co.listedPrice, customer)}</div>
                          </TextDecoration>
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    ))}
                </CellStyled>
              )
            },
          },
          {
            Header: 'Discounted Price',
            accessor: 'salePrice',
            headerClassName: 'text-right',
            className: 'text-right',
            sortable: false,
            width: 120,
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  {original.salePrice ? (
                    <div>
                      <TextDecoration decoration={original.hasOwnProperty('discountedPrice') ? 'line-through' : null}>
                        <div>{formatCustomerCurrency(original.salePrice, customer)}</div>
                      </TextDecoration>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                  {original.customOptionValues &&
                    original.customOptionValues.map(co => (
                      <div key={co.productCode}>
                        {co.salePrice ? (
                          <TextDecoration decoration={co.hasOwnProperty('discountedPrice') ? 'line-through' : null}>
                            <div>{formatCustomerCurrency(co.salePrice, customer)}</div>
                          </TextDecoration>
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    ))}
                </CellStyled>
              )
            },
          },
          {
            Header: 'Unit Price',
            accessor: 'discountedPrice',
            headerClassName: 'text-right',
            className: 'text-right',
            sortable: false,
            width: 120,
            Cell: ({ original, index }) => {
              return discountInd === 'C' ? (
                <CellStyled>
                  <CustomDiscountInput
                    {...{
                      original,
                      customer,
                      index,
                      readOnly,
                      handleOrderItemChangeCustomDiscountedPrice,
                    }}
                  />
                  {original.customOptionValues &&
                    original.customOptionValues.map((co, coIndex) => (
                      <div key={co.productCode} style={{ padding: '2px 0' }}>
                        <CustomDiscountInput
                          {...{
                            original: co,
                            customer,
                            index,
                            readOnly,
                            handleOrderItemChangeCustomDiscountedPrice: handleCustomOptionChangeCustomDiscountedPrice,
                            coIndex,
                          }}
                        />
                      </div>
                    ))}
                </CellStyled>
              ) : (
                <CellStyled>
                  <>
                    {original.hasOwnProperty('appliedCoupon') && (
                      <div style={{ position: 'absolute', left: 15 }}>
                        {original.hasOwnProperty('appliedCoupon') && renderStar()}
                      </div>
                    )}

                    {original.discountedPrice ? (
                      <div
                        className={
                          (original.hasOwnProperty('appliedCoupon') ||
                            (original.discount && original.discount.discountInd === 'P')) &&
                          'text-danger'
                        }
                      >
                        {formatCustomerCurrency(original.discountedPrice, customer)}
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                    {original.customOptionValues &&
                      original.customOptionValues.map(co => (
                        <div key={co.productCode} style={{ position: 'relative' }}>
                          {co.hasOwnProperty('appliedCoupon') && (
                            <div style={{ position: 'absolute', left: 20 }}>
                              {co.hasOwnProperty('appliedCoupon') && renderStar()}
                            </div>
                          )}

                          {co.discountedPrice ? (
                            <div
                              className={
                                (co.hasOwnProperty('appliedCoupon') ||
                                  (co.discount && co.discount.discountInd === 'P')) &&
                                'text-danger'
                              }
                            >
                              {formatCustomerCurrency(co.discountedPrice, customer)}
                            </div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      ))}
                  </>
                </CellStyled>
              )
            },
          },
          {
            Header: 'Subtotal',
            accessor: 'subTotal',
            width: 120,
            headerClassName: 'd-flex justify-content-end',
            className: 'text-right',
            sortable: false,
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  <div>{formatCustomerCurrency(original.subTotal || 0, customer)}</div>
                </CellStyled>
              )
            },
          },
          {
            Header: 'Action',
            accessor: null,
            sortable: false,
            width: 70,
            headerClassName: 'd-flex justify-content-center',
            className: 'text-center',
            Cell: ({ original }) => (
              <CellStyled>
                <IconButton
                  disabled={readOnly}
                  icon="ios-trash"
                  className="p-1"
                  style={{ marginTop: '-4px' }}
                  title="Remove product"
                  onClick={() => handleDeleteItem(original)}
                  iconSize="small"
                />
                {original.customOptionValues &&
                  original.customOptionValues.map((co, coIndex) => (
                    <IconButton
                      disabled={readOnly}
                      key={co.productCode}
                      icon="ios-trash"
                      className="d-block mx-auto p-1"
                      style={{ marginTop: '-4px' }}
                      title="Remove custom option"
                      onClick={() => handleDeleteCustomOption(original, coIndex)}
                      iconSize="small"
                    />
                  ))}
              </CellStyled>
            ),
          },
        ]}
        data={orderRepository.CRUD.data.orderItems}
        defaultPageSize={5}
        defaultSorted={[
          {
            id: 'product.product.name',
            desc: false,
          },
        ]}
        showRowPerPage={false}
        showPager={false}
        getTdProps={(_, rowInfo, column, _2) => {
          if (column.id === 'ic2Organizations') {
            return {
              title: rowInfo?.row?.ic2Organizations?.length
                ? rowInfo.row.ic2Organizations.map(item => item.org_code).join(', ')
                : '',
            }
          }
          return null
        }}
      />
    )
  }
}

class CustomDiscountInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: formatDecimalValByCustomer(props.original.discountedPrice, props.customer) || '',
    }
    this.input = React.createRef()
  }
  handleChange = e => {
    this.setState({ value: this.input.current.value })
  }
  render() {
    const { original, readOnly, index, handleOrderItemChangeCustomDiscountedPrice, coIndex = null } = this.props
    return (
      <InputStyled
        bsSize="sm"
        className="text-right"
        type="number"
        id={original.id}
        innerRef={this.input}
        value={this.state.value}
        readOnly={readOnly}
        onChange={this.handleChange}
        onBlur={e => handleOrderItemChangeCustomDiscountedPrice(e.target.value, index, coIndex)}
        style={{ padding: '2px 0' }}
      />
    )
  }
}

const CellStyled = styled.div`
  position: relative;
  > div {
    padding: 0.4rem 5px;
    &.with-icon-button {
      display: flex;
      align-items: center;
      padding: 0 5px;
    }
  }
  button {
    &.with-icon {
      position: relative;
      padding-left: 28px;
      .icon {
        position: absolute;
        top: -1px;
        left: 8px;
        bottom: 0px;
      }
    }
  }
`

const InputStyled = styled(Input)`
  width: 100px;
  height: 15px !important;
  font-size: 11px !important;
  margin: 3px 0 !important;
  background: rgb(239, 239, 239) !important;
  color: #cc0092 !important;
  padding: 2px 5px !important;
  &[readonly] {
    background-color: transparent !important;
    pointer-events: none;
  }
  &[type='number'],
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
`

const RebateMsrpInputStyled = styled(InputStyled)`
  &&& {
    background-color: #ffffff !important;
    width: 100% !important;
    height: 20px !important;
    padding: 4px !important;
    text-align: center;
    color: inherit !important;
    &:read-only {
      background-color: transparent !important;
      border: none !important;
    }
  }
`

//CustomOption Add Button
const COButtonStyled = styled(Button)`
  &&& {
    border-radius: 50%;
    background: transparent;
    color: #ffb81c;
    margin-top: -5px;
    text-align: center;
    width: 15px;
    padding: 0;
    height: 15px;
    font-size: 11px;
  }
`

const ColumnContentWithIconStyled = styled.div`
  position: relative;
  padding-right: 22px;
  .icon {
    position: absolute;
    top: -4px;
    right: 2px;
    bottom: 0;
    font-size: 1.125rem;
  }
`

export default inject('orderRepository', 'storeProductRepository')(observer(ProductsTable, {}))
