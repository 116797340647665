import React, { Component } from 'react'

import { Button, Col, FormGroup, FormText, Row } from 'reactstrap'
import { CardElement, injectStripe } from 'react-stripe-elements'
import styled from 'styled-components'

import { inject, observer } from 'mobx-react'
import ErrorsList from 'components/Common/ErrorsList'
import Prompt from 'components/Common/Prompt'

class StripeForm extends Component {
  state = {
    empty: true,
    complete: false,
    error: null,
    loading: false,
    submitting: false,
    otp: false,
    stripe3DSecureData: {},
  }
  componentDidMount() {
    // this.token = this.props.stripe.createToken({ name: 'Name' })
  }
  handleChangeCCField = obj => {
    if (obj.error) {
      this.setState({
        error: obj.error.message,
        empty: obj.empty,
        complete: obj.complete,
      })
    } else {
      this.setState({ error: null, empty: obj.empty, complete: obj.complete })
    }
  }
  handleStripePay = ({ token, isCancelled = false }) => {
    const self = this
    if (!isCancelled) {
      this.props.paymentRepository.submitPayment(
        {
          stripeToken: token.id,
        },
        this.props.match.params.token,
        res => {
          this.setState(
            {
              loading: false,
              submitting: false,
            },
            () => {
              if (res.errorCode === 402 && res.clientSecret) {
                this.props.stripe.handleNextAction({ clientSecret: res.clientSecret }).then(resp => {
                  self.handleStripePay({ token, isCancelled: !!resp.error })
                })
              }
              if (res.status === 'PAID' && !res.errorCode) {
                this.props.history.push(`${res.id}/success`)
              }
            }
          )
        }
      )
    } else {
      this.setState({
        loading: false,
        submitting: false,
      })
    }
  }
  handlePay = () => {
    this.setState(
      {
        loading: true,
        submitting: true,
      },
      async () => {
        const { token, error } = await this.props.stripe.createToken({
          total: {
            amount: this.props.paymentRepository.CRUD.data.amount,
          },
        })
        if (token && !error) {
          this.handleStripePay({ token })
        } else {
          this.setState({
            error: error.message,
          })
        }
        this.setState({
          loading: false,
          submitting: false,
        })
      }
    )
  }
  render() {
    const { error, empty, complete } = this.state
    const {
      paymentRepository: {
        Payment: { errors, loading, submitting },
      },
    } = this.props
    return (
      <React.Fragment>
        <Prompt promptOnExit={submitting || this.state.submitting} isConfirm={false} />
        <Row>
          <Col lg={8} className="col-12 ">
            {!!errors.length && <ErrorsList errors={errors} />}
            <FormGroupStyled>
              <div className="form-control col-12">
                <CardElement
                  // hidePostalCode
                  onChange={this.handleChangeCCField}
                  style={
                    {
                      // base: {
                      //   color: '#32325D',
                      //   // fontFamily: 'Helvetica, Arial, sans-serif',
                      //   '::placeholder': {
                      //     color: '#ccc',
                      //     fontWeight: 400,
                      //   },
                      // },
                      // invalid: {
                      //   color: '#E25950',
                      // },
                    }
                  }
                />
              </div>
              <Button
                color="primary"
                style={{ minWidth: loading || this.state.loading ? 150 : 100 }}
                disabled={empty || !complete || loading || this.state.loading}
                onClick={this.handlePay}
                className="d-none d-md-block d-lg-block"
              >
                {loading || this.state.loading ? 'Please wait...' : 'Pay'}
              </Button>
            </FormGroupStyled>
            {!!error && (
              <FormText color="danger" style={{ fontSize: 12 }}>
                {error}
              </FormText>
            )}
          </Col>
          <Col md={12} className="d-sm-block d-md-none d-lg-none mt-3">
            <Button
              color="primary"
              disabled={empty || !complete || loading || this.state.loading}
              onClick={this.handlePay}
              className="btn-block"
            >
              {loading || this.state.loading ? 'Please wait...' : 'Pay'}
            </Button>
          </Col>
          <Col md={12}>
            <p className="d-flex align-items-center mt-3">
              <i className="icon ion-md-lock text-primary mr-2" />
              Your card details are protected using PCI DSS security standards.
            </p>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const FormGroupStyled = styled(FormGroup)`
  margin-bottom: 0px !important;
  display: flex;
  .form-control {
    border: 1px solid rgb(200, 199, 199) !important;
    border-radius: 5px !important;
    height: 40px;
    margin-right: 10px;
  }
  .StripeElement {
    padding-top: 5px;
  }
`

export default injectStripe(inject('paymentRepository')(observer(StripeForm)))
